import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 获取招聘工作列表（个人）
 * @param 
 * method:get
 * path:'/app/study/member/company/job/release'
*/

export const jobList = params => http.get(`${baseUrl}/member/company/job/release`, { params })

/**
 * 获取招聘工作列表（企业）
 * @param 
 * method:get
 * path:'/app/study/member/company/job'
*/

export const myJobList = params => http.get(`${baseUrl}/member/company/job`, { params })

/**
 * 获取招聘详细信息
 * @param 
 * method:get
 * path:`/app/study/member/company/job/${id}`
*/

export const getJobInfo = id => http.get(`${baseUrl}/member/company/job/${id}`)

/**
 * 获取个人简历
 * @param 
 * method:get
 * path:`/app/study/member/cv/my`
*/

export const getResume = () => http.get(`${baseUrl}/member/cv/my`)

/**
 * 根据id查询个人简历
 * @param 
 * method:get
 * path:`/app/study/member/cv/${id}`
*/

export const getIdResume = id => http.get(`${baseUrl}/member/cv/${id}`)

/**
 * 修改个人简历
 * @param 
 * method:put
 * path:`/app/study/member/cv`
*/

export const editResume = params => http.put(`${baseUrl}/member/cv`, params)

/**
 * 获取沟通列表
 * @param 
 * method:get
 * path:`/app/study/member/chat`
*/

export const getContactList = () => http.get(`${baseUrl}/member/chat`)

/**
 * 删除聊天
 * @params
 * method:delete
 * path:`/app/study/member/chat/${id}`
 */

 export const delContact = id => http.delete(`${baseUrl}/member/chat/${id}`)

 /**
 * 获取沟通历史信息
 * @param 
 * method:get
 * path:`/app/study/member/chat/${id}/history`
*/

export const getChatList = (id, params) => http.get(`${baseUrl}/member/chat/${id}/history`, {params})

 /**
 * 发送聊消息
 * @param 
 * method:post
 * path:`/app/study/member/chat/${id}/send`
*/

export const sendMsg = (id, params) => http.post(`${baseUrl}/member/chat/${id}/send`, params)

/**
 * 置顶/取消置顶聊天
 * @params
 * method:put
 * path:`/app/study/member/chat/${id}/top`
 * path:`/app/study/member/chat/${id}/cancelTop`
 */

 export const top = (id, status) => http.put(`${baseUrl}/member/chat/${id}/${status == 0 ? 'top' : 'cancelTop'}`)

  /**
 * 沟通工作
 * @param 
 * method:post
 * path:`/app/study/member/chat/start/${id}`
*/

export const startContact = id => http.post(`${baseUrl}/member/chat/start/${id}`)

/**
 * 获取公司信息
 * @param 
 * method:get
 * path:`/app/study/member/company/my`
*/

export const getCompanyInfo = () => http.get(`${baseUrl}/member/company/my`)

/**
 * 修改公司信息
 * @param 
 * method:put
 * path:`/app/study/member/company`
*/

export const editCompanyInfo = params => http.put(`${baseUrl}/member/company`, params)

/**
 * 发布/撤回招聘
 * @params
 * method:put
 * path:`/app/study/member/company/job/${id}/release}`
 * path:`/app/study/member/company/job/${id}/cancel}`
 */

 export const runRecruit = (id, status) => http.put(`${baseUrl}/member/company/job/${id}/${status == 1 ? 'cancel' : 'release'}`)

 /**
 * 删除招聘
 * @params
 * method:delete
 * path:`/app/study/member/company/job/${id}`
 */

  export const delRecruit = id => http.delete(`${baseUrl}/member/company/job/${id}`)

/**
 * 发布招聘
 * @params
 * method:post
 * path:`/app/study/member/company/job`
 */

export const releaseRecruit = params => http.post(`${baseUrl}/member/company/job`, params)

/**
 * 修改招聘
 * @params
 * method:put
 * path:`/app/study/member/company/job`
 */

export const editRecruit = params => http.put(`${baseUrl}/member/company/job`, params)
