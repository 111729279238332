<template>
  <div class="wrap">
    <div class="header">
      <div class="container" style="text-align: left">
        <p class="tips">
          <span style="cursor: pointer" @click="$router.go(-1)">返回</span>
          <i class="el-icon-arrow-right"></i>
          <span>招聘进行中</span>
        </p>
        <div class="jobhead">
          <p class="name">{{ info.jobName }}</p>
          <p class="salary">{{ info.salary }}</p>
          <div class="tags">
            <span v-for="(item, idx) in info.requirements" :key="idx">{{
              item
            }}</span>
          </div>
        </div>
        <div class="address">
          <i class="el-icon-location-outline icon"></i>
          <p>{{ info.jobAddress }}</p>
        </div>
        <div class="btns">
          <el-button
            v-if="type == 0"
            type="primary"
            class="contact_btn"
            @click="goContact"
            >立即沟通</el-button
          >
        </div>
      </div>
    </div>
    <div class="main container" style="text-align: left">
      <div class="conmpany_info">
        <img :src="info.companyLogo" alt="logo" class="logo" />
        <div class="name">
          <p class="hr">{{ info.hr }}</p>
          <p class="companyName">{{ info.companyName }}</p>
        </div>
      </div>
      <div class="detail">
        <h2 class="label">岗位职责:</h2>
        <div class="desc">
          <p>{{ info.jobInfo }}</p>
        </div>

        <h2 class="label">工作地点:</h2>
        <div class="desc">
          <p>{{ info.jobAddress }}</p>
        </div>

        <h2 class="label">公司介绍:</h2>
        <div class="desc">
          <p>{{ info.companyInfo }}</p>
        </div>

        <h2 class="label">联系方式:</h2>
        <div class="desc">
          <p v-if="isLogin">{{ info.contactUs }}</p>
          <p v-else>登陆后查看</p>
        </div>
      </div>
    </div>

    <el-dialog
      class="tips_dialog"
      :visible.sync="show"
      width="40%"
      :show-close="false"
      center
    >
      <div class="d_body">
        <i class="el-icon-warning-outline"></i>
        <p class="tips">如对招聘信息感兴趣，请您先完善个人信息</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" @click="goComplete">去完善</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getJobInfo, getResume, startContact } from "@/api/recruit";
export default {
  name: "jobDetail",
  data() {
    return {
      info: {},
      show: false,
      haveInfo: false,
      type: 0,
    };
  },
  created() {
    this.type = this.$route.query.type || 0;
    this.getInfo();
    if (this.isLogin)
      getResume().then((res) => {
        if (res.code == 200) {
          this.haveInfo = res.data.mobile ? true : false;
        } else {
          console.log(res.msg);
        }
      });
  },
  computed: {
    isLogin() {
      let list = Object.keys(this.$store.state.personalInfo);
      if (list.length === 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getInfo() {
      this.$loading.show();
      getJobInfo(this.$route.query.jobId).then((res) => {
        this.$loading.hide();
        if (res.code == 200) {
          res.data.requirements = JSON.parse(res.data.requirements);
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goContact() {
      if (!this.isLogin) {
        // 未登录
        this.$router.push("/login");
      } else if (!this.haveInfo) {
        // 未完善信息
        this.show = true;
      } else {
        startContact(this.$route.query.jobId).then((res) => {
          if (res.code == 200) {
            this.$router.push({
              path: "/recruit",
              query: {
                idx: 2,
                type: 1,
                jobId: this.$route.query.jobId,
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    goComplete() {
      this.show = false;
      this.$router.push("./personalData");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  min-height: 693px;
  background: #f3f3f3;

  .header {
    padding: 24px 0;
    background: #fff;

    .tips {
      font-size: 12px;
      color: #333;
    }

    .jobhead {
      padding: 10px 0;
      overflow: hidden;

      .name {
        display: inline-block;
        margin-right: 30px;
        font-size: 32px;
        color: #333;
      }

      .salary {
        display: inline-block;
        font-size: 28px;
        color: #0d79ff;
      }

      .tags {
        float: right;

        span {
          display: inline-block;
          margin-left: 20px;
          padding: 8px;
          background: #f0f0f0;
          border-radius: 5px;
          text-align: center;
          font-size: 16px;
          color: #969696;
        }
      }
    }

    .address {
      font-size: 16px;

      .icon {
        margin-right: 5px;
      }

      p {
        display: inline-block;
        color: #646464;
      }
    }

    .btns {
      margin-top: 15px;

      .contact_btn {
        width: 140px;
        height: 40px;
        background: #0d79ff;
        border-color: #0d79ff;
        font-size: 16px;
      }
    }
  }

  .main {
    padding-bottom: 30px;

    .conmpany_info {
      padding: 30px 0;
      border-bottom: solid 1px #e1e1e1;

      .logo {
        margin-right: 20px;
        width: 56px;
        height: 56px;
      }

      .name {
        display: inline-block;
        font-size: 18px;
        color: #323232;
        vertical-align: middle;

        .hr {
          margin-bottom: 8px;
        }
      }
    }

    .detail {
      .label {
        margin-top: 30px;
        font-size: 14px;
        color: #323232;
      }

      .desc {
        margin-top: 10px;
        line-height: 2;

        p {
          white-space: pre-wrap;
        }
      }
    }
  }

  ::v-deep.tips_dialog {
    .el-dialog {
      border-radius: 10px;

      .d_body {
        text-align: center;

        .el-icon-warning-outline {
          font-size: 80px;
          color: #faad14;
        }

        .tips {
          margin-top: 30px;
          font-size: 21px;
          color: #666;
        }
      }

      .el-dialog__footer {
        padding-top: 50px;
        padding-bottom: 50px;

        .btn {
          width: 160px;
          height: 44px;
          background: #1890ff;
          border-color: #1890ff;
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>